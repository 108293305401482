/**
 *
 * page-home.scss
 *
 */
.home-main {
	overflow: hidden;
}

/* ------------------------------------------------------------
 home-kv
------------------------------------------------------------ */
.home-kv {
	position: relative;
}

.home-kv__inner {
	position: relative;
}

@media screen and (min-width: 1024px) {
	.home-kv__inner {
		padding: 28rem 0 19rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-kv__inner {
		padding: 11rem 0 5.5rem;
	}
}

/* ---------- home-kv__catch ---------- */
.home-kv__catch {
	font-weight: 700;
	--line-height: calc( 40 / 18 );
	letter-spacing: calc( 200 / 1000 * 1em);
}

@media screen and (min-width: 1024px) {
	.home-kv__catch {
		position: absolute;
		z-index: 1;
		top: 23.6rem;
		right: calc( ( 50 / var(--inner-width) ) * 100%);
		font-size: var(--font-size18);
	}
}

@media screen and (max-width: 1023px) {
	.home-kv__catch {
		width: fit-content;
		margin-left: auto;
		margin-right: var(--contents-outside);
		font-size: var(--font-size16);
	}
}

@media screen and (max-width: 560px) {
	.home-kv__catch {
		text-align: center;
		margin-right: auto;
	}
}

.home-kv__catch span[style="--delay: 1140ms;"] {
	margin-right: calc( -7 / 18 * 1em);
}

/* ---------- home-kv__txt ---------- */
.home-kv__txt {
	text-transform: uppercase;
	writing-mode: vertical-rl;
	font-family: var(--font-sans-serif);
	padding-top: 0.1px;
	padding-bottom: 0.1px;
	font-weight: 500;
	letter-spacing: calc( 244 / 1000 * 1em);
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.home-kv__txt::before, .home-kv__txt::after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	font-size: inherit;
}

.home-kv__txt::before {
	margin-right: var(--font-sans-serif-top);
}

.home-kv__txt::after {
	margin-left: var(--font-sans-serif-bottom);
}

@media screen and (min-width: 1024px) {
	.home-kv__txt {
		font-size: var(--font-size14);
	}
}

@media screen and (max-width: 1023px) {
	.home-kv__txt {
		display: none;
	}
}

.home-kv__txt.-left {
	right: 100%;
}

.home-kv__txt.-right {
	left: 100%;
}

/* ---------- home-kv__image ---------- */
.home-kv__image {
	margin: 0 auto;
}

@media screen and (min-width: 1024px) {
	.home-kv__image {
		max-width: 160rem;
		width: calc( ( 1200 / var(--inner-width) ) * 100%);
	}
}

@media screen and (max-width: 1023px) {
	.home-kv__image {
		width: 100%;
		margin-top: 2rem;
	}
}

/* ------------------------------------------------------------
 home-works
------------------------------------------------------------ */
.home-works {
	--gap: 3rem;
}

@media screen and (min-width: 1024px) {
	.home-works {
		--card-width: 35rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-works {
		width: var(--contents-lg);
		margin: 0 auto;
	}
}

.home-works__row + .home-works__row {
	margin-top: var(--gap);
}

@media screen and (min-width: 1024px) {
	.home-works__row:nth-of-type(2n) article {
		transform: translateX(calc( (var(--card-width) + var(--gap)) / 2 * -1));
	}
}

@media screen and (min-width: 1024px) {
	.home-works__row article {
		width: var(--card-width);
		margin-left: var(--gap);
	}
}

@media screen and (max-width: 1023px) {
	.home-works__row__list {
		display: grid;
		gap: var(--gap);
	}
}

@media screen and (max-width: 1023px) and (min-width: 561px) {
	.home-works__row__list {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 560px) {
	.home-works__row__list {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* ---------- home-works__button ---------- */
.home-works__button {
	margin: 0 auto;
}

@media screen and (min-width: 1024px) {
	.home-works__button {
		margin-top: 9.5rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-works__button {
		margin-top: 6rem;
	}
}

/* ------------------------------------------------------------
 home-about
------------------------------------------------------------ */
@media screen and (min-width: 1024px) {
	.home-about {
		margin-top: 18.5rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-about {
		margin-top: 12rem;
	}
}

.home-about__inner {
	position: relative;
}

@media screen and (min-width: 1024px) {
	.home-about__inner {
		--space-left: calc( ( 100 / var(--inner-width) ) * 100%);
		--width-right: calc( ( 580 / var(--inner-width) ) * 100%);
	}
}

/* ---------- home-about__catch ---------- */
.home-about__catch {
	font-weight: 700;
	padding-top: calc( var(--padding-top) * 0.1rem);
}

@media screen and (min-width: 1024px) {
	.home-about__catch {
		font-size: var(--font-size40);
		--padding-top: 50;
		margin-left: var(--space-left);
		letter-spacing: calc( 200 / 1000 * 1em);
	}
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
	.home-about__catch {
		padding-top: calc( var(--padding-top) / 1500 * var( --ww ));
	}
}

@media screen and (max-width: 1023px) {
	.home-about__catch {
		--padding-top: 0;
		font-size: var(--font-size26);
		letter-spacing: calc( 100 / 1000 * 1em);
	}
}

/* ---------- home-about__txt ---------- */
.home-about__txt {
	margin-top: calc( var(--margin-top) * 0.1rem);
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
	.home-about__txt {
		margin-top: calc( var(--margin-top) / 1500 * var( --ww ));
	}
}

@media screen and (min-width: 1024px) {
	.home-about__txt:nth-of-type(2n-1) {
		margin-left: var(--space-left);
	}
}

@media screen and (min-width: 1024px) {
	.home-about__txt:nth-of-type(2n) {
		width: var(--width-right);
		margin-left: auto;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__txt.-unique1 {
		--margin-top: 100;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__txt.-unique1 {
		--margin-top: 60;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__txt.-unique2 {
		--margin-top: 342;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__txt.-unique2 {
		--margin-top: 73;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__txt.-unique3 {
		--margin-top: 316;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__txt.-unique3 {
		--margin-top: 70;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__txt.-unique4 {
		--margin-top: 412;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__txt.-unique4 {
		--margin-top: 67;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__txt.-unique5 {
		--margin-top: 260;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__txt.-unique5 {
		--margin-top: 119;
	}
}

/* ---------- home-about__illust ---------- */
.home-about__illust {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

@media screen and (min-width: 1024px) {
	.home-about__illust {
		width: calc( ( var(--width) / var(--inner-width) ) * 100%);
		position: absolute;
		top: calc( var(--top) * 0.1rem);
		bottom: calc( var(--bottom) * 0.1rem);
		left: calc( ( var(--left) / var(--inner-width) ) * 100%);
		right: calc( ( var(--right) / var(--inner-width) ) * 100%);
	}
}

@media screen and (min-width: 1024px) and (max-width: 1500px) {
	.home-about__illust {
		top: calc( var(--top) / 1500 * var( --ww ));
		bottom: calc( var(--bottom) / 1500 * var( --ww ));
	}
}

@media screen and (max-width: 1023px) {
	.home-about__illust {
		position: relative;
		top: calc( var(--top) * 0.1rem);
		bottom: calc( var(--bottom) * 0.1rem);
		left: calc( var(--left) * 0.1rem);
		right: calc( var(--right) * 0.1rem);
		width: calc( var(--width) * 0.1rem);
		margin: 0 auto;
		margin-top: calc( var(--margin-top) * 0.1rem);
	}
}

.home-about__illust img {
	max-width: calc( var(--width) * 0.1rem);
	width: 100%;
}

@media screen and (min-width: 1024px) {
	.home-about__illust.-unique1 {
		--width: 406;
		--top: 96;
		--right: -192;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__illust.-unique1 {
		position: absolute;
		--width: 202;
		--top: 81;
		--right: -92;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__illust.-unique2 {
		--width: 263;
		--top: 273;
		--right: 219;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__illust.-unique2 {
		--width: 233;
		--margin-top: 73;
	}
	.home-about__illust.-unique2 img {
		transform: translateX(0.6rem);
	}
}

@media screen and (min-width: 1024px) {
	.home-about__illust.-unique3 {
		--width: 366;
		--top: 608;
		--left: 135;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__illust.-unique3 {
		--width: 223;
		--margin-top: 70;
	}
	.home-about__illust.-unique3 img {
		transform: translateX(0.6rem);
	}
}

@media screen and (min-width: 1024px) {
	.home-about__illust.-unique4 {
		--width: 189;
		--top: 1090;
		--right: 414;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__illust.-unique4 {
		position: absolute;
		--width: 120;
		--top: 3;
		--left: -69;
		--margin-top: 0;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__illust.-unique5 {
		--width: 445;
		--right: -50;
		--bottom: 974;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__illust.-unique6 {
		--width: 241;
		--left: 280;
		--bottom: 680;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__illust.-unique7 {
		--width: 258;
		--left: -198;
		--bottom: 627;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__illust.-unique7 {
		position: absolute;
		--width: 128;
		--top: -95;
		--left: -173;
		--margin-top: 0;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__illust.-unique8 {
		--width: 165;
		--right: -126;
		--bottom: 573;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__illust.-unique8 {
		position: absolute;
		--width: 90;
		--right: -11;
		--bottom: 2;
		--margin-top: 0;
	}
}

@media screen and (min-width: 1024px) {
	.home-about__illust.-unique9 {
		--width: 280;
		--right: 149;
		--bottom: 189;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__illust.-unique9 {
		--width: 170;
		--margin-top: 77;
	}
	.home-about__illust.-unique9 img {
		transform: translateX(0.4rem);
	}
}

/* ---------- home-about__illusts ---------- */
@media screen and (max-width: 1023px) {
	.home-about__illusts {
		position: relative;
		width: calc( var(--width) * 0.1rem);
		margin: 0 auto;
		margin-top: calc( var(--margin-top) * 0.1rem);
	}
	.home-about__illusts.-group1 {
		--width: 299;
		--margin-top: 90;
	}
	.home-about__illusts.-group2 {
		--width: 110;
		--margin-top: 110;
		transform: translateX(-1.1rem);
	}
}

/* ---------- home-about__button ---------- */
.home-about__button {
	margin: 0 auto;
}

@media screen and (min-width: 1024px) {
	.home-about__button {
		margin-top: 24.6rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-about__button {
		margin-top: 7.6rem;
	}
}

/* ------------------------------------------------------------
 home-service
------------------------------------------------------------ */
@media screen and (min-width: 1024px) {
	.home-service {
		margin-top: 32.5rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-service {
		margin-top: 15.3rem;
	}
}

@media screen and (min-width: 1024px) {
	.home-service__inner {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}

/* ----------------------------------------
 home-service__header
---------------------------------------- */
@media screen and (min-width: 1024px) {
	.home-service__header {
		flex: 1;
	}
}

/* ---------- home-service__txt ---------- */
@media screen and (min-width: 1024px) {
	.home-service__txt {
		margin-top: 5rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-service__txt {
		margin-top: 3rem;
	}
}

/* ---------- home-service__button ---------- */
@media screen and (min-width: 1024px) {
	.home-service__button {
		margin-top: 7.6rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-service__button {
		margin: 6.0rem auto 0;
	}
}

/* ----------------------------------------
 home-service__list
---------------------------------------- */
.home-service__list {
	border-bottom: var(--solid);
}

@media screen and (min-width: 1024px) {
	.home-service__list {
		width: calc( ( 810 / var(--inner-width) ) * 100%);
		margin-top: 1.1rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-service__list {
		margin-top: 6rem;
	}
}

.home-service__list li {
	border-top: var(--solid);
	font-weight: 500;
	letter-spacing: calc( 100 / 1000 * 1em);
	--line-height: calc( 52 / 26 );
}

@media screen and (min-width: 1024px) {
	.home-service__list li {
		font-size: var(--font-size18);
		padding: 5rem 0.9rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-service__list li {
		font-size: var(--font-size13);
		padding: 2.8rem 0;
	}
}

/* ------------------------------------------------------------
 home-blog
------------------------------------------------------------ */
.home-blog {
	position: relative;
	background-color: var(--color-bg3);
}

@media screen and (min-width: 1024px) {
	.home-blog {
		margin-top: 32.5rem;
		padding: 0 0 16rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-blog {
		margin-top: 15.4rem;
		padding: 0 0 8rem;
	}
}

/* ---------- home-blog__header ---------- */
.home-blog__header {
	position: relative;
}

.home-blog__header::before {
	content: '';
	display: block;
	width: var(--ww);
	height: 50%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	background-color: var(--color-bg);
}

.home-blog__header > * {
	position: relative;
	z-index: 1;
}

/* ---------- home-blog__list ---------- */
.home-blog__list {
	overflow: hidden;
}

@media screen and (min-width: 1024px) {
	.home-blog__list {
		margin-top: 11.5rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-blog__list {
		width: var(--contents);
		margin: 6.0rem auto 0;
	}
}

@media screen and (min-width: 1024px) {
	.home-blog__list article {
		width: 59rem;
		margin: 0 3.4rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-blog__list article:nth-of-type(n+3) {
		display: none;
	}
}

@media screen and (max-width: 1023px) {
	.home-blog__list__inner {
		display: grid;
	}
}

@media screen and (max-width: 1023px) and (min-width: 561px) {
	.home-blog__list__inner {
		grid-template-columns: 1fr 1fr;
		gap: 3rem;
	}
}

@media screen and (max-width: 560px) {
	.home-blog__list__inner {
		gap: 5rem;
	}
}

/* ---------- home-blog__button ---------- */
.home-blog__button {
	margin: 0 auto;
}

@media screen and (min-width: 1024px) {
	.home-blog__button {
		margin-top: 12rem;
	}
}

@media screen and (max-width: 1023px) {
	.home-blog__button {
		margin-top: 6rem;
	}
}
